import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "checks";
// initial state
const state = () => ({
  all: [],
  checks: [],
  check: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getCheck: (state) => {
    return state.check;
  },

  getChecks: (state) => {
    return state.checks;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let checks = response.data;
      commit("setChecks", checks);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let check = response.data;
      commit("setCheck", check);
    });
  },

  async print({ dispatch, commit }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Votre chèque est en cours de téléchargement, veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .get(api + "_print/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Cheque.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printReceiptVoucher({ dispatch, commit }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Votre bon de réception de chèque est en cours de téléchargement, veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .get(api + "_receipt_voucher_print/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Bon de réception de chèque.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "checks-customers-index",
        });
      }
    });
  },

  async storeSupplierCheck({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "checks-suppliers-index",
        });
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "checks-customers-index",
        });
      }
    });
  },

  async search({ state }, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.checks = state.all.filter(function (user) {
      return (
        user.customer.firstName.toUpperCase().indexOf(value.toUpperCase()) >
          -1 ||
        user.customer.lastName.toUpperCase().indexOf(value.toUpperCase()) >
          -1 ||
        user.customer.fullName.toUpperCase().indexOf(value.toUpperCase()) >
          -1 ||
        user.customer_reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

// mutations
const mutations = {
  setChecks(state, checks) {
    state.all = checks;
    state.checks = checks;
  },
  setCheck(state, check) {
    state.check = check;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
