import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "payments";
// initial state
const state = () => ({
  all: [],
  payments: [],
  payment: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getPayment: (state) => {
    return state.payment;
  },

  getPayments: (state) => {
    return state.payments;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let payments = response.data;
      commit("SET_PAYMENTS", payments);
      window.location;
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let payment = response.data;
      commit("setPayment", payment);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "payments-index",
        });
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "payments-index",
        });
      }
    });
  },

  async destroy({ dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "payments-index",
        });
      }
    });
  },

  async filter({ dispatch, commit }, data) {
    await axios.post(api + "_filter", data).then((response) => {
      let payments = response.data;
      commit("SET_PAYMENTS", payments);
    });
  },

  async print({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "La liste des paiements du client est en cours de téléchargement. Veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .post(api + "_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Paiements.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printReceipt({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Votre reçu de paiement est en cours de téléchargement, veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .get(api + "_print_receipt/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Recu.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  SET_PAYMENTS(state, payments) {
    state.all = payments;
    state.payments = payments;
  },
  setPayment(state, payment) {
    state.payment = payment;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.payments = state.all.filter(function (payment) {
      return (
        payment.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        payment.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        payment.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        payment.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
