const constant = {
  // api: "http://localhost:8400/api/",
  // url: "http://localhost:8400/",

  // api: "http://192.168.1.103:8400/api/",
  // url: "http://192.168.1.103:8400/",

  // api: "https://api.stock.peascom.store/api/",
  // url: "https://api.stock.peascom.store/",

  //  api: "https://api.peascom.store/api/",
  //  url: "https://api.peascom.store/",

  api: "https://api.app.goodgroupe.com/api/",
  url: "https://api.app.goodgroupe.com/",
};
export default constant;
