import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "items";
// initial state
const state = () => ({
  all: [],
  items: [],
  item: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getItem: (state) => {
    return state.item;
  },

  getItems: (state) => {
    return state.items;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let items = response.data;
      commit("setItems", items);
    });
  },

  async getItemsPrices({ commit }) {
    await axios.get(api + "_prices").then((response) => {
      let items = response.data;
      commit("setItemsPrices", items);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let item = response.data;
      commit("setItem", item);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async storePrice({ commit, dispatch }, data) {
    await axios.post(api + "_prices", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItemsPrices", response.data.data);
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "items-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        let items = response.data.data;
        commit("setItems", items);
      }
    });
  },
};

// mutations
const mutations = {
  setItems(state, items) {
    state.all = items;
    state.items = items;
  },
  setItemsPrices(state, items) {
    state.all = items;
    state.items = items;
  },
  setItem(state, item) {
    state.item = item;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.items = state.all.filter(function (item) {
      return (
        item.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        item.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        item.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        item.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
