import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import access from "./modules/access";
import auth from "./modules/auth";
import backup from "./modules/backup";
import check from "./modules/check";
import coldroom from "./modules/coldroom";
import credit from "./modules/credit";
import customer from "./modules/customer";
import deposit from "./modules/deposit";
import discount from "./modules/discount";
import employee from "./modules/employee";
import expense from "./modules/expense";
import item from "./modules/item";
import notification from "./modules/notification";
import order from "./modules/order";
import payment from "./modules/payment";
import production from "./modules/production";
import purchase from "./modules/purchase";
import rawMaterial from "./modules/rawMaterial";
import quotation from "./modules/quotation";
import sale from "./modules/sale";
import section from "./modules/section";
import society from "./modules/society";
import slaughter from "./modules/slaughter";
import statistic from "./modules/statistic";
import stock from "./modules/stock";
import supplier from "./modules/supplier";
import user from "./modules/user";
import vehicle from "./modules/vehicle";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    access,
    auth,
    backup,
    check,
    customer,
    credit,
    coldroom,
    deposit,
    discount,
    employee,
    expense,
    item,
    notification,
    order,
    payment,
    production,
    purchase,

    rawMaterial,
    quotation,
    sale,
    section,
    society,
    slaughter,
    statistic,
    stock,
    supplier,
    user,
    vehicle,
  },
});
